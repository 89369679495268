import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'

import WhyNav from '../../components/why-nav'
import Layout from '../../components/layout'

export default class Faq extends React.Component {
  render() {
    const faqs = get(this, 'props.data.allContentfulFaq.edges')
    // allow auto meta description
    return (
      <Layout pageTitle="FAQ">
        <WhyNav path={this.props.path} />
        <h2>Frequently Asked Questions</h2>
        <Accordion>
          {faqs.map(({ node }, i) => (
            <Card key={i}>
              <Accordion.Toggle
                as={Card.Header}
                variant="link"
                eventKey={i}
                className="btn"
              >
                {node.question}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={i}>
                <Card.Body
                  dangerouslySetInnerHTML={{
                    __html: node.answer.childMarkdownRemark.html,
                  }}
                />
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Layout>
    )
  }
}

export const faqQuery = graphql`
  query FaqQuery {
    allContentfulFaq(sort: { fields: updatedAt }) {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
